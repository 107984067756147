import React, { useEffect } from 'react';

import { getInitialApiRequest, stripeConfigRequest } from './redux/actions/initialApiAction';
import { getPlan } from './redux/actions/profileAction';
import { getQuestionDataRequest } from './redux/actions/reportIssueAction';
import { getAdData } from "./redux/actions/adAction";
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from './redux/reducer';


function InitialComponent() {
    const { dev_base_url, live_base_url } = useSelector<IRootState, any>((state) => state.initialApiReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getInitialApiRequest());
    }, []);
    useEffect(() => {
        if (dev_base_url || live_base_url) {
            dispatch(stripeConfigRequest());
            dispatch(getPlan());
            dispatch(getQuestionDataRequest());
            dispatch(getAdData({}));
        }
    }, [dev_base_url, live_base_url]);
    return (
        <></>
    );
}

export default InitialComponent;