import { call, put, takeEvery } from "redux-saga/effects";
import { get, initialApiUrl } from "../../api";
import {
  GET_INITIAL_API_REQUEST,
  GET_INITIAL_API_FAILURE,
  GET_INITIAL_API_SUCCESS,
  STRIPE_CONFIG_REQUEST,
  STRIPE_CONFIG_FAILURE,
  STRIPE_CONFIG_SUCCESS,
} from "../types";

async function getInitialApi() {
  try {
    const response = await get(`${initialApiUrl}/api-config`);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

function* fetchInitialApi() {
  try {
    const response = yield call(getInitialApi);
    yield put({ type: GET_INITIAL_API_SUCCESS, response });
  } catch (e) {
    yield put({ type: GET_INITIAL_API_FAILURE, message: e.message });
  }
}

async function getStripeConfig() {
  try {
    const response = await get(`/stripe-config`);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

function* fetchStripeConfigApi() {
  try {
    const response = yield call(getStripeConfig);
    yield put({ type: STRIPE_CONFIG_SUCCESS, response });
  } catch (e) {
    yield put({ type: STRIPE_CONFIG_FAILURE, message: e.message });
  }
}

export function* initialApiSaga() {
  yield takeEvery(GET_INITIAL_API_REQUEST, fetchInitialApi);
  yield takeEvery(STRIPE_CONFIG_REQUEST, fetchStripeConfigApi);
}

export default initialApiSaga;
